body {
    background-color: #32746d;
    margin: 0;
}

.MuiOutlinedInput-root fieldset {
    border-color: white;
}

.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #9ec5ab;
}

.MuiOutlinedInput-root.MuiInputBase-colorSecondary fieldset {
    border-color: #32746d;
}

.MuiOutlinedInput-root.MuiInputBase-colorSecondary.Mui-focused fieldset {
    border-color: #104f55;
}

.MuiButton-root.MuiButton-contained {
    background-color: white;
    color: #32746d;
}

.MuiButton-root.MuiButton-contained:hover {
    background-color: #9ec5ab;
    color: #104f55;
}

.MuiSpeedDial-root .MuiButtonBase-root {
    background-color: white;
    color: #104f55;
}

.MuiSpeedDial-root .MuiButtonBase-root:hover {
    color: white;
}

.MuiSpeedDial-actions .MuiButtonBase-root:hover {
    background-color: #9ec5ab;
}